import fallbackJson from './locales/en.json';

/** Убрать, когда заработает fallback в либе */
export function removeEmptyFromLocale(obj: object | any, fallback: object | any = fallbackJson): any {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      if (isNullish(obj[key])) {
        // delete obj[key];
        obj[key] = fallback[key];
      }
    } else removeEmptyFromLocale(obj[key], fallback[key]);
  }
  return obj;
}
